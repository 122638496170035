<template>
    <div class="index">
        <Header/>
        <div class="banner">
            <el-carousel>
                <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                    <img :src="item.imgUrl"/>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="box1">

            <div class="box-w">

                <div class="nav">

                    <div class="nav-item" @click="$router.push('/errorQuestPractice')">
                        <img src="../assets/img/home/quest-error.png"/>
                        <div class="right">
                        <div class="title">
                         错题
                        </div>
                        <div class="sub-title">{{userPracticeCount.errorCount}}题</div>
                        </div>
                    </div>
                    <div class="nav-item" @click="$router.push('/transcriptList')">
                        <img src="../assets/img/home/exam.png"/>
                        <div class="right">
                            <div class="title">
                                考试成绩
                            </div>
                            <div class="sub-title">平均{{userPracticeCount.avgScore}}分</div>
                        </div>
                    </div>
                    <div class="nav-item" @click="$router.push('/collectQuestPractice')">
                        <img src="../assets/img/home/collect.png"/>
                        <div class="right">
                            <div class="title">
                             收藏的题目
                            </div>
                            <div class="sub-title">{{userPracticeCount.collectCount}}题</div>
                        </div>
                    </div>
                    <div class="nav-item" @click="$router.push('/myNote')">
                        <img src="../assets/img/home/icon_note.png"/>
                        <div class="right">
                            <div class="title">
                                笔记
                            </div>
                            <div class="sub-title">{{userPracticeCount.noteCount||0}}篇</div>
                        </div>
                    </div>
                    <div class="nav-item" @click="$router.push('/statistics')">
                        <img src="../assets/img/home/vital.png"/>
                        <div class="right">
                            <div class="title">
                                统计
                            </div>
                            <div class="sub-title">累计做了{{userPracticeCount.statisticsCount}}题</div>
                        </div>
                    </div>

                </div>

                <div class="panel">

                    <div class="left">

                       <div class="title">题库练习</div>
                        <div class="box">

                            <div class="box-item box-item-bg1" @click="$router.push('/chapter')">
                                <div class="title">
                                <div>章节练习</div>
                                <div class="desc">完整的复习资料</div>
                                </div>
                            </div>
                            <div class="box-item box-item-bg2" @click="$router.push({path:'/exam',query:{id:1}})">
                                <div class="title">
                                <div>模拟考试</div>
                                <div class="desc">模拟真实考试环境</div>
                                </div>
                            </div>
                            <div class="box-item box-item-bg3" @click="toExam">
                                <div class="title">
                                <div>综合测试</div>
                                <div class="desc">测试综合知识和技能</div>
                                </div>
                            </div>
                            <div class="box-item box-item-bg4" @click="$router.push('/randomPractice')">
                                <div class="title">
                                <div>随机练习</div>
                                <div class="desc">随机进行题库练习</div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="right">
                        <div class="box">
                           <div style="display: flex;flex-direction: column">
                               <div style="flex: 1;margin: 20px">
                               <div class="title">AI智慧导师</div>
                               <div class="sub-title1">大模型智慧导师</div>
                               <div class="sub-title2">实时全方位大帮助和支持</div>
                               </div>
                               <div class="go" @click="aiDialog=true">马上去试试<i class="el-icon-arrow-right"></i></div>
                           </div>
                            <div>
                                <img src="../assets/img/home/ai-bg.png"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>

        <div class="box2">

            <div class="box-w">

                <section-title title="视频学习" desc="汇聚海量知识资源，提升学习效率" @moreClick="$router.push('/course')"></section-title>
                <div class="video-box">

                    <div v-for="(item,index) in videoList" :key="index" @click="playVideo(item)">
                    <course-item :item="item" ></course-item>
                    </div>

                </div>
                <empty v-if="videoEmpty"></empty>

            </div>
        </div>

        <div class="line box-w"></div>

        <div class="box3">

            <div class="box-w">

                <section-title title="文章学习" desc="汇聚海量知识资源，提升学习效率" @moreClick="$router.push('/article')"></section-title>

                <div class="article-box">

                    <div v-for="(item,index) in articleList" :key="index">
                    <article-item  :item="item"></article-item>
                    </div>

                </div>

                <empty v-if="articleEmpty"></empty>
            </div>

        </div>


        <Footer/>
        <!---选科目-->
        <el-dialog title="" :close-on-click-modal="false" :visible.sync="categoryDialog" :show-close="false" width="450px">

<div style="padding: 20px">
    <div style="font-size: 22px;font-weight: bold;">选择科目</div>
    <div style="font-size: 14px;color: rgba(60,60,67,0.6);margin-bottom: 10px">选择想要学习的科目进行学习</div>
    <Category @onClose="categoryDialog=false"/>
</div>

</el-dialog>
        <!---ai助手-->
        <el-dialog title="" custom-class="ai-dialog" :visible.sync="aiDialog" :close-on-click-modal="false" width="1018px" @open="stopScroll()"
                   @close="openScroll()">

            <ai ref="ai"/>
        </el-dialog>

    </div>

</template>

<script>
    import Header from '@/components/Header.vue'
    import SectionTitle from '@/components/SectionTitle.vue'
    import CourseItem from '@/components/CourseItem.vue'
    import ArticleItem from '@/components/ArticleItem.vue'
    import Ai from '@/components/Ai'
    import Category from '@/components/Category'
    import Footer from '@/components/Footer'
    import Empty from '@/components/Empty'
    import {GetBanner,GetCategories,GetCourseList,GetArticleList,GetUserPracticeCount} from '@/api/list'
    import {GetTextList} from '@/api/text'
    export default {
        name: "Index",
        data(){

            return {
                aiDialog:false,
                categoryDialog:false,
                videoDialog:false,
                videoId:0,
                poster:'',
                videoSrc:'',
                categoryId:this.$store.state.user.category.id,
                bannerList:[],
                userPracticeCount:{
                    errorCount:0,
                    collectCount:0,
                    statisticsCount:0,
                    noteCount:0,
                    avgScore:0
                },
                videoList:[],
                videoEmpty:false,
                articleList:[],
                articleEmpty:false

            }
        },
        components:{
            Header,
            SectionTitle,
            CourseItem,
            ArticleItem,
            Ai,
            Category,
            Empty,
            Footer
        },

        watch:{

            "$store.state.user.category":{

                deep:true,
                handler:function (newVal) {
                    this.categoryId=newVal.id;

                }
            }


        },
        created(){

            this.categoryDialog=!this.$store.state.user.category.id;

            GetCategories().then(res=>{
                this.$store.commit('category/saveCategories',res.data)
            });

            this.getBannerData();

            if(this.categoryId){
                this.getCourseData();
                this.getArticleData();
                this.getUserPracticeCount();
            }
            //监听科目改变
            this.bus.$on(this.ON_CATEGORY_CHANGE,()=>{
                this.categoryId = this.$store.state.user.category.id;
                this.getCourseData();
                this.getArticleData();
                this.getUserPracticeCount();

            })

        },
        methods:{

            //加载轮播图
            getBannerData(){

                GetBanner().then(res=>{

                    this.bannerList=res.data;

                })

            },
            //获取用户错误收藏数
            getUserPracticeCount(){
                GetUserPracticeCount(this.categoryId).then(res=>{

                    let { wrongSubject,avgScore,myCollect,statistics,noteCount}=res.data
                    this.userPracticeCount={
                        errorCount:wrongSubject,
                        avgScore:avgScore,
                        collectCount:myCollect,
                        statisticsCount:statistics,
                        noteCount:noteCount

                    }
                })
            },

            //综合测试
            toExam(){

                GetTextList(this.categoryId).then(()=>{
                    $router.push({path:'/exam'})
                })

            },

            //加载课程数据
            getCourseData(){
                GetCourseList(1,8,this.categoryId,'',0).then(res=>{

                    this.videoList=res.data;
                    this.videoEmpty=this.videoList.length===0
                })

            },
            //加载文章数据
            getArticleData(){

                GetArticleList(8,1,this.categoryId,'','0').then(res=>{
                    this.articleList=res.data
                    this.articleEmpty=this.articleList.length===0;
                })

            },
            //跳转
            handelJump(path,query){

                this.$router.push({
                    path:path,
                    query:query
                })
            },

            //播放视频
            playVideo(item){

                this.videoId=item.id
                this.poster=item.coverImg;
                this.videoSrc=item.url;
                this.videoDialog=true;

            },


            stopScroll()
            {
                let mo=function(e){e.preventDefault();};
                document.body.style.overflow='hidden';
                document.addEventListener("touchmove",mo,false);//禁止页面滑动
                setTimeout(()=>{
                this.$refs.ai.scrollBottom()
                },50)

            },
            openScroll()
            {
                let mo=function(e){e.preventDefault();};
                document.body.style.overflow='';//出现滚动条
                document.removeEventListener("touchmove",mo,false);
            },



        },
        beforeDestroy(){
            //解绑
            this.bus.$off(this.ON_CATEGORY_CHANGE)
        }

    }
</script>

<style lang="less" scoped>

    .index{
        .banner{

            img{
                width: 100%;
                height: 360px;
            }
        }
    }
   /deep/ .ai-dialog{

        border-radius: 10px;
        overflow: hidden;
    }

    .box-w {
        width: 1200px;
        margin: 0 auto;
    }
    .box1{
        background: url("../assets/img/home/box1-bg.png") no-repeat;
        background-size: 100% 100%;
        height: 583px;
        overflow: hidden;

        .nav{
            margin-top: 20px;
            border-radius: 10px;
            width: 100%;
            height: 110px;
            overflow: hidden;
            background-color: #fff;
            box-shadow: 0 3px 6px 1px rgba(0,0,0,0.05);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .nav-item{
                width: 100%;
                height: 106px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 2px solid #fff;

              .right{
                  margin-left: 16px;
              }

                &:hover{
                    background: linear-gradient(134deg, #D9E8FF 0%, #F7FCFF 100%);

                    border-radius: 10px;
                }


            }
            img{

                width: 56px;
                height: 56px;

            }
            .title{

                font-size: 17px;

            }
            .sub-title{

                font-size: 12px;
                color: rgba(60,60,67,0.6);

            }

        }

        .panel{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;


            .left{
                background-color: #fff;
                width: 590px;
                height: 346px;
                box-shadow: 0 3px 6px 1px rgba(0,0,0,0.05);
                border-radius: 10px;
                .title{
                    font-size: 22px;
                    margin: 15px;

                }
            .box{

                margin: 15px;
                display: grid;
                grid-template-columns: repeat(2,1fr);
                gap: 15px;

                .box-item{

                    width: 100%;
                    height: 126px;
                    border-radius: 10px;
                    cursor: pointer;
                    background-size: 100% 100%;
                    .title{
                        font-size: 22px;
                        margin: 15px;

                        .desc{
                            font-size: 17px;
                            color: #3C3C43;
                        }
                    }


                }

                .box-item-bg1{
                    background: url("../assets/img/home/box1.png") no-repeat;

                }
                .box-item-bg2{
                    background: url("../assets/img/home/box2.png") no-repeat;

                }
                .box-item-bg3{
                    background: url("../assets/img/home/box3.png") no-repeat;

                }
                .box-item-bg4{
                    background: url("../assets/img/home/box4.png") no-repeat;

                }
            }

            }

            .right{

                width: 590px;
                height: 346px;
                background: linear-gradient(180deg, #D9E8FF 0%, #F7FCFF 100%);
                box-shadow: 0 3px 6px 1px rgba(0,0,0,0.05);
                border-radius: 10px;

                .box{

                    display: flex;

                    .title{
                        font-size: 22px;
                        color: #00317B;
                    }
                    .sub-title1{
                        margin-top: 25px;
                        font-size:16px;

                        &::before{
                            content: '01';
                            background-color: #3B89FF;
                            font-size: 12px;
                            color: #fff;
                            border-radius: 2px;
                            padding: 0 2px;
                            margin-right: 5px;

                        }
                    }
                    .sub-title2{
                        margin-top: 25px;
                        font-size:16px;
                        &::before{
                            content: '02';
                            background-color: #3B89FF;
                            font-size: 12px;
                            color: #fff;
                            border-radius: 2px;
                            padding: 0 2px;
                            margin-right: 5px;

                        }
                    }

                    .go{
                        flex-shrink: 0;
                        margin: 0 0 50px 20px;
                        width: 134px;
                        height: 41px;
                        line-height:41px;
                        background: rgba(59,137,255,0.1);
                        border-radius: 10px;
                        text-align: center;
                        font-size: 15px;
                        color: #00317B;
                        cursor: pointer;
                    }
                }
            }
        }


    }


    .box2{


        .video-box{
            margin-top: 15px;
            display: grid;
            grid-template-columns: repeat(4,1fr);
            gap: 15px;

        }


    }
    .line{
        margin-top: 30px;
        height: 1px;
        background-color: #f2f2f2;
        margin-bottom: 30px;
    }

    .box3{

        margin-bottom: 20px;
        .article-box{
            margin-top: 15px;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 15px;
        }

    }


</style>
