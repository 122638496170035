<template>
    <div class="section">
        <div style="flex: 1">
            <div class="title">
                {{title}}
            </div>
            <div class="desc">{{desc}}</div>
        </div>
        <div style="flex-shrink: 0;cursor: pointer" @click="moreClick">
            查看更多<i class="el-icon-arrow-right"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SectionTitle",
        props:{
            title:{
                type:String,
                default:''
            },
            desc:{
                type:String,
                default: ''
            },
            moreText:{
                type:String,
                default:'查看更多'
            }
        },
        methods:{
            moreClick(){
                this.$emit('moreClick')
            }
        }

    }
</script>

<style lang="less" scoped>

    .section{
        display: flex;

        .title{
            font-size: 34px;

        }
        .desc{
            font-size: 14px;
            color: rgba(60,60,67,0.6);
        }
    }

</style>
