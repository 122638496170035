import request from '../utils/request'


//对话接口
export function chat(data,onDownloadProgress) {


    return request({
        url:'/api/ai/generateStream',
        method:'post',
        data:data,
        onDownloadProgress:onDownloadProgress
    })

}

//获取会话列表
export function getSessionList() {

    return request({
        url:'/api/ai/getChatSessionList',
        method: 'get'
    })
}

//创建会话
export function createSessionApi(data) {

    return request({
        url:'/api/ai/createSession',
        method: 'post',
        data
    })
}

//更新会话
export function updateSessionApi(data) {

    return request({
        url:'/api/ai/updateSession',
        method: 'post',
        data
    })
}

//删除会话
export function delSessionApi(data) {

    return request({
        url:'/api/ai/delSession',
        method: 'delete',
        data
    })
}


//保存聊天记录
export function saveChatRecordApi(data) {

    return request({
        url:'/api/ai/saveChatRecord',
        method: 'post',
        data
    })
}

//获取聊天记录
export function getChatRecordList(params) {
    return request({
        url:'/api/ai/getChatRecordList',
        method: 'get',
        params
    })
}


