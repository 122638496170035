<template>
    <div class="ai">
        <div class="left">
            <div class="top">
                <div style="flex-shrink: 0">
                    <div class="title">AI智慧导师</div>
                    <div class="sub-title1">大模型智慧导师</div>
                    <div class="sub-title2">实时全方位的帮助和支持</div>
                </div>
                <div>
                    <img src="../assets/img/home/ai-small.png"/>
                </div>
            </div>

            <div class="session-list">

                <div :class="index===currentSessionIndex?'session-item active':'session-item'" v-for="(item,index) in sessionList" :key="index" @click="changeSession(index)">

                    <div class="title">{{item.title||'新聊天'}}</div>
                    <div class="bottom">
                        <div>{{item.createTime}}</div>
                        <div>{{item.total}}条对话</div>
                    </div>
                    <div class="del-btn" @click="delSession(index)">⎼</div>

                </div>

            </div>
            <div class="new-session">

                <div class="btn" @click="createSession">新的聊天</div>

            </div>
        </div>
        <div class="right">

            <div class="chat-list">

                <div v-for="(item,index) in chatList" :key="index">
                <div class="message-left" v-if="!item.me">
                    <div class="avatar">
                        <img src="../assets/img/home/ai-avatar.png"/>
                    </div>
                    <div class="message-box">
                        <div class="message">
                            <div v-if="item.loading" class="blinker"></div>
                             <div v-else class="markdown-body" v-html="item.content"></div>

                        </div>
                        <div class="time">{{item.createTime}}</div>
                    </div>

                </div>
                <div class="message-right" v-else>

                    <div class="message-box">
                        <div class="message">
                          {{item.content}}
                        </div>
                    </div>
                    <div class="avatar">
                        <img src="../assets/img/user.png"/>
                    </div>
                </div>
                </div>

            </div>
            <div class="chat-bottom">

                <div class="chat-input">
                    <input placeholder="请输入你的问题(回车自动发送)" v-model="msg" class="input" @keyup.enter="send"></input>
                    <div class="send" @click="send">
                        <img src="../assets/img/home/send.png"/>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {chat,createSessionApi,updateSessionApi,delSessionApi,getSessionList,saveChatRecordApi,getChatRecordList} from '@/api/ai'
    import MarkdownIt from 'markdown-it'
    import mdKatex from '@traptitech/markdown-it-katex'
    import hljs from 'highlight.js'
    import 'highlight.js/styles/default.css'


    export default {
        name: "Ai",

        data(){
            return{

                mdi:null,
                msg:'',
                sessionList:[],
                currentSessionIndex:0,
                chatList:[],
                lastChatIndex:0,

            }
        },

        created(){
             this.mdi = new MarkdownIt({
                linkify: true,
                highlight:(code, language)=> {
                    const validLang = (language && hljs.getLanguage(language))
                    if (validLang) {
                        const lang = language||''
                        return this.highlightBlock(hljs.highlight(lang, code, true).value, lang)
                    }
                    return this.highlightBlock(hljs.highlightAuto(code).value, '')
                },
            });

            this.mdi.use(mdKatex, { blockClass: 'katexmath-block rounded-md p-[10px]', errorColor: ' #cc0000' })

            // this.sessionList = JSON.parse(localStorage.getItem('sessionList')||'[]');
            // if(this.sessionList.length===0){
            //     this.createSession();
            // }else {
            //     this.chatList = this.sessionList[this.currentSessionIndex].talks
            // }

            this.getSessionListData()

        },

        methods: {

              //高亮显示
              highlightBlock(str, lang) {
             return `<pre class="code-block-wrapper"><div class="code-block-header"><span class="code-block-header__lang">${lang}</span></div><code class="hljs code-block-body ${lang}">${str}</code></pre>`
             },

            //获取会话列表
            getSessionListData(){
                getSessionList().then(res=>{
                    this.sessionList=res.data;

                    if(this.sessionList.length===0){
                        this.createSession();
                    }else {
                       // this.chatList = this.sessionList[this.currentSessionIndex].talks
                        this.getChatRecordListData()
                    }
                })
            },

            //新建对话
            createSession(){

               // this.sessionList.splice(0,0,{sessionId:'#chat/'+new Date().getTime(),
               //          content:'',
               //          time:new Date().toLocaleString(),
               //          talks:[]
               //      });
               //
               //  this.currentSessionIndex=0;
               //  this.chatList=[]
               //  localStorage.setItem('sessionList',JSON.stringify(this.sessionList))
                createSessionApi({title:'新的对话'}).then(()=>{
                    this.getSessionListData()
                })

            },
            //切换对话
            changeSession(index){

                  this.currentSessionIndex=index;
                 // this.chatList=this.sessionList[index].talks||[]
                 this.getSessionListData()

            },
            //删除对话
            delSession(index){

                this.$confirm('此操作将永久删除对话, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.sessionList.splice(index,1)
                    // localStorage.setItem('sessionList',JSON.stringify(this.sessionList));
                    //
                    // if(this.sessionList.length===0){
                    //     this.createSession()
                    // }
                    // this.currentSessionIndex=0;
                    // this.chatList=this.sessionList[0].talks
                    // this.$message.success('删除成功')

                    delSessionApi([this.sessionList[index].id]).then(()=>{
                        this.getSessionListData()
                        this.$message.success('删除成功')

                    })
                })

            },

            //发送消息
            send(){

             if(this.msg.length>0){

                let message= {
                         sessionId:this.sessionList[this.currentSessionIndex].id,
                         me:true,
                         content:this.msg,
                         loading:false,
                         time:new Date().toLocaleString()
                 }
                 this.chatList.push(message);
                 this.sessionList[this.currentSessionIndex].total++

                 //更新会话内容
                 if(this.sessionList[this.currentSessionIndex].title==='新的对话'){

                     this.sessionList[this.currentSessionIndex].title= this.msg.substring(0,this.msg.length>10?10:this.msg.length)
                     let session = this.sessionList[this.currentSessionIndex];
                     updateSessionApi(session)
                 }

                 this.chatList.push({
                     sessionId:this.sessionList[this.currentSessionIndex].id,
                     me:false,
                     content:'',
                     loading:true,
                     time:new Date().toLocaleString()
                 });

                 this.scrollBottom();
                 chat({
                     "prompt": this.msg,
                     "userId": `#chat/${this.sessionList[this.currentSessionIndex].id}`,
                     "network": true,
                     "system": "",
                     "withoutContext": false,
                     "stream": false
                 },(e)=>{

                   this.chatList[this.chatList.length-1].content= this.mdi.render(e.target.responseText);
                   this.chatList[this.chatList.length-1].time=new Date().toLocaleString();
                   this.chatList[this.chatList.length-1].loading=false;
                   this.scrollBottom();

                 }).then(()=>{

                    // this.sessionList[this.currentSessionIndex].talks=this.chatList
                     this.sessionList[this.currentSessionIndex].total++;
                     this.saveChatRecord();

                   //  localStorage.setItem('sessionList',JSON.stringify(this.sessionList))

                 }).catch(()=>{
                     let msg = '请求出错了，请重发送试试！'
                     let time = new Date().toLocaleString();
                     this.chatList[this.chatList.length-1].content=msg;
                     this.chatList[this.chatList.length-1].time=time;
                     this.chatList[this.chatList.length-1].loading=false;
                     // this.sessionList[this.currentSessionIndex].talks.push({
                     //     me:false,
                     //     content:msg,
                     //     time:time
                     // });
                    // localStorage.setItem('sessionList',JSON.stringify(this.sessionList))

                     this.scrollBottom();
                 });

                 this.msg=''

             }


            },

            //保存聊天记录
            saveChatRecord(){

                saveChatRecordApi(this.chatList.slice(this.chatList.length-2,this.chatList.length)).then(()=>{
                    console.log('保存聊天记录')
                })
            },
            //获取聊天记录
            getChatRecordListData(){
                let sessionId = this.sessionList[this.currentSessionIndex].id;
                getChatRecordList({sessionId:sessionId}).then(res=>{
                    this.chatList=res.data;
                    setTimeout(()=> {
                        this.scrollBottom();
                    },50)
                })
            },
            //滚动到底部
            scrollBottom() {

                this.$nextTick(() => {

                    let dom = document.querySelector('.chat-list');
                    dom.scrollTop = dom.scrollHeight

                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .ai{
        display: flex;
        height: 640px;
        border-radius: 10px;

        .left{
            width: 289px;
            height: 100%;
            background: linear-gradient(180deg, #D9E8FF 0%, #F7FCFF 100%);
            display: flex;
            flex-direction: column;

            .top{
                display: flex;
                padding: 15px;
                align-items: center;

                border-bottom: 1px solid #ccc;

                .title{
                    font-size: 22px;
                    color: #00317B;
                    font-weight: bold;
                }

                .sub-title1{
                    margin-top: 6px;
                    font-size: 10px;
                    font-weight: 400;
                    &::before{
                        content: '01';
                        background-color: #3B89FF;
                        font-size: 10px;
                        color: #fff;
                        border-radius: 2px;
                        padding: 0 2px;
                        margin-right: 2px;

                    }
                }
                .sub-title2{
                    margin-top: 10px;
                    font-size: 10px;
                    font-weight: 400;
                    &::before{
                        content: '02';
                        background-color: #3B89FF;
                        font-size: 10px;
                        color: #fff;
                        border-radius: 2px;
                        padding: 0 2px;
                        margin-right: 2px;

                    }
                }

                img{
                    vertical-align: middle;
                }
            }
            .session-list{
                flex: 1;
                padding: 5px 15px 15px 15px;
                overflow-y: scroll;
                -ms-overflow-style: none;

                &::-webkit-scrollbar{
                    width:0 !important;
                    display: none;
                    overflow: -moz-scrollbars-none;
                }
                .session-item{
                    margin-top: 10px;
                    background-color: #fff;
                    padding: 10px;
                    border-radius: 8px;
                    cursor: pointer;
                    position: relative;


                    .title{
                        font-size: 15px;
                        font-weight: bold;
                        color: #000;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .bottom{
                        margin-top: 6px;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 400;
                        color: rgba(60,60,67,0.6);
                        font-size: 12px;
                    }

                    .del-btn{
                        width: 20px;
                        height: 20px;
                        background:red;
                        position: absolute;
                        display: none;
                        border-radius: 20px;
                        top: -5px;
                        left: -5px;
                        font-size: 18px;
                        line-height: 15px;
                        text-align: center;
                        color: #fff;

                    }

                    &:hover{

                        .del-btn{
                            display: block;
                        }
                    }
                }

                .active{
                    border: 1px solid #3B89FF;
                }

            }
            .new-session{
                border-top: 1px solid #ccc;
                flex-shrink: 0;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                .btn{
                    background: rgba(59,137,255,0.1);
                    border-radius: 10px 10px;
                    width: 220px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border: 1px solid #3B89FF;
                    cursor: pointer;
                    font-weight: bold;
                    font-size: 15px;
                    color: #00317B;
                }
            }
        }
        .right{
            width: 731px;
            height: 100%;
            display: flex;
            flex-direction: column;

            .chat-list{
                flex: 1;
                margin: 35px 20px 0 20px;

                overflow-y: scroll;
                -ms-overflow-style: none;
                &::-webkit-scrollbar{
                    width:0 !important;
                    display: none;
                    overflow: -moz-scrollbars-none;
                }

                .message-box{

                    .message{

                        border-radius: 0 6px 6px 6px;
                        font-size: 16px;
                        font-weight: 400;
                        word-break: break-word;
                        color: #000;
                        div{
                           /deep/ p{
                                margin-top: 15px;

                            }
                           /deep/ ol li{

                               margin-left: 20px;
                               list-style: decimal;
                            }
                        }
                    }
                    .time{
                        margin-top: 10px;
                        font-weight: 400;
                        color: rgba(60,60,67,0.6);
                        font-size: 15px;
                    }
                }

                .message-left{

                    margin-top: 15px;

                    display: flex;
                    justify-content: flex-start;
                    .avatar{
                        margin-right: 10px;
                    }

                    .avatar img{
                        width: 36px;
                        height: 36px;
                    }
                    .message-box{
                        margin-right: 46px;
                    }
                    .message{
                        background: #F8F8F8;
                        padding: 1px 10px 15px 10px;


                    }

                }
                .message-right{

                    margin-top: 15px;

                    display: flex;
                    justify-content: flex-end;
                    .avatar{
                        margin-left: 10px;
                    }
                    .avatar img{
                        width: 36px;
                        height: 36px;
                    }

                    .message-box{
                        margin-left: 46px;
                    }

                    .message{
                        background: #D8EDFF;
                        padding: 10px;

                    }
                }

            }

            .chat-bottom{

                width: 100%;
                flex-shrink: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 80px;
                .chat-input{
                    width: 90%;
                    background-color: #EFEFF4;
                    display: flex;
                    padding: 10px;
                    border-radius: 10px;

                    .input{
                        flex: 1;
                        background-color: transparent;
                        font-size: 17px;
                        padding: 5px;
                        border: none;



                    }
                    input::-webkit-input-placeholder{

                        color: rgba(60,60,67,0.3);

                    }

                    input::-moz-placeholder{   /* Mozilla Firefox 19+ */

                        color: rgba(60,60,67,0.3);

                    }

                    input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */

                        color: rgba(60,60,67,0.3);

                    }

                    input:-ms-input-placeholder{  /* Internet Explorer 10-11 */

                        color: rgba(60,60,67,0.3);

                    }
                    .send{
                        display: flex;
                        align-items: center;
                        margin-left: 5px;
                        cursor: pointer;
                    }
                }

            }


        }


        .blinker{
            -webkit-animation-name: blinker;
            animation-name: blinker;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
            animation-timing-function: cubic-bezier(1, 0, 0, 1);
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            display: inline-block;
            width: 2px;
            height: 20px;
            margin-left: 10px;
            margin-top: 15px;
            border-radius: 3px;
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
            background: #000;
        }

        @-webkit-keyframes blinker {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
        @keyframes blinker {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }


        .markdown-body {
            background-color: transparent;
            font-size: 14px;

            p {
                white-space: pre-wrap;
            }

            ol {
                list-style-type: decimal;
            }

            ul {
                list-style-type: disc;
            }

            pre code,
            pre tt {
                line-height: 1.65;
            }

            .highlight pre,
            pre {
                background-color: #fff;
            }

            code.hljs {
                padding: 0;
            }

            .code-block {
                &-wrapper {
                    position: relative;
                    padding-top: 24px;
                }

                &-header {
                    position: absolute;
                    top: 5px;
                    right: 0;
                    width: 100%;
                    padding: 0 1rem;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: #b3b3b3;

                    &__copy{
                        cursor: pointer;
                        margin-left: 0.5rem;
                        user-select: none;
                        &:hover {
                            color: #65a665;
                        }
                    }
                }
            }
        }

        html.dark {

            .highlight pre,
            pre {
                background-color: #282c34;
            }
        }
    }


</style>
